.logo {
  margin-bottom: 0;
  font-size: 4rem;
  text-shadow: black 1px 1px 3px;
  color: white;
  font-family: 'Waiting for the Sunrise';
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .logo {
    font-size: 2rem;
  }
}
