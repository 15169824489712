.drink_recipe {
  display: grid;
  grid-template: 1fr / 70px 1fr;
  padding: 10px 0;
  background: url('../../assets/layout/line_dotted_h.png') 0 100% repeat-x;
  overflow: hidden;
}
.drink_recipe img {
  width: 60px;
  height: 60px;
}
.list a:last-child .drink_recipe {
  background: none;
}

.drink_recipe h3 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}