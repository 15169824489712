menu.footer_menu {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: auto;
  padding: 0;
}

menu.footer_menu div {
  width: calc(100% / 3);
  height: 100%;
  border-right: 1px solid #28371f;
  border-left: 1px solid #9cc087;
  color: white;
  text-shadow: rgba(0, 0, 0, 0.4) 0px 1px 0px;
  font-size: 1rem;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

menu.footer_menu div:last-child {
  border-right: none;
}

menu.footer_menu div:first-child {
  border-left: none;
}

@media only screen and (max-width: 900px) {
  menu.footer_menu div {
    border-left: none;
    border-right: none;
  }
}
