menu.header_menu * {
  --borderRadius: 6px;
}

menu.header_menu a {
  padding: 10px 20px;
  background: linear-gradient(to bottom, #abce8d 1px, #6fa060 2px, #384E2C calc(100% - 2px), #28371F 1px);
  border-right: 1px solid #28371F;
  color: white;
  text-shadow: rgba(0, 0, 0, 0.4) 0px 1px 0px;
  font-size: 1.1rem;
}
menu.header_menu a:first-child {
  border-top-left-radius: var(--borderRadius);
  border-bottom-left-radius: var(--borderRadius);
}
menu.header_menu a:last-child {
  border-top-right-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  border-right: none;
}

menu.header_menu a:hover {
  background: linear-gradient(to bottom, #1E2616, #2D4022);
}

menu.header_menu a.active {
  background: linear-gradient(to bottom, #cbf1a9 1px, #9fd88d 2px, #678357 calc(100% - 2px), #394d2e 1px);
}


.mobile menu.header_menu * {
  --borderRadius: 6px;
}

.mobile menu.header_menu a {
  padding: 10px 20px;
  background: linear-gradient(to bottom, #abce8d 1px, #6fa060 2px, #384E2C 100%);
  border-right: none;
  margin-bottom: 5px;
  color: white;
  text-shadow: rgba(0, 0, 0, 0.4) 0px 1px 0px;
  font-size: 1.1rem;
}
.mobile menu.header_menu a {
  border-radius: var(--borderRadius);
}
.mobile menu.header_menu a:last-child {
  margin-bottom: 0;
}

.mobile menu.header_menu a.active {
  background: linear-gradient(to bottom, #cbf1a9 1px, #9fd88d 2px, #678357 100%);
}
