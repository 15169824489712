header {
  width: 100%;
  background:  url('../../assets/layout/header_img.jpg') top center, url('../../assets/layout/header_bg.jpg') repeat-x;
  position: fixed;
  z-index: 2;
  padding: 0 20px;
}

header.desktop .container {
  height: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

header.mobile .container {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
header.mobile menu {
  width: auto;
  height: auto;
  padding: 10px;
  border-radius: 10px;
  position: absolute;
  top: 50px;
  right: 10px;
  display: flex;
  flex-direction: column;
  background: rgba(0,0,0,0.7);
}

header.mobile .links {
  width: 80px;
}

header.mobile .menu_button {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background: linear-gradient(to bottom, #abce8d 1px, #6fa060 2px, #384E2C calc(100% - 2px), #28371F 1px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
}
header.mobile .menu_button svg {
  width: 150%;
  height: 150%;
  flex-shrink: 0;
}