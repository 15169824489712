.cloud * {
  --color-0: #2d63a0;
  --color-1: #2e63a1;
  --color-2: #336dac;
  --color-3: #103761;
  --color-4: #3e80c2;
  --color-5: #4489ce;
  --color-6: #4993d9;
  --color-7: #4f9ce4;
  --color-8: #54a6ef;
  --color-9: #5aaffa;
}

.cloud {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
}
.cloud > ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
  text-align: center;
}

.desktop .cloud a.weight1 {
  color: var(--color-0);
  font-size: 1rem;
}
.desktop .cloud a.weight2 {
  color: var(--color-1);
  font-size: 1.1rem;
}
.desktop .cloud a.weight3 {
  color: var(--color-2);
  font-size: 1.2rem;
}
.desktop .cloud a.weight4 {
  color: var(--color-3);
  font-size: 1.3rem;
}
.desktop .cloud a.weight5 {
  color: var(--color-4);
  font-size: 1.5rem;
}
.desktop .cloud a.weight6 {
  color: var(--color-5);
  font-size: 1.7rem;
}
.desktop .cloud a.weight7 {
  color: var(--color-6);
  font-size: 2rem;
}
.desktop .cloud a.weight8 {
  color: var(--color-7);
  font-size: 2.1rem;
}
.desktop .cloud a.weight9 {
  color: var(--color-8);
  font-size: 2.3rem;
}
.desktop .cloud a.weight10 {
  color: var(--color-9);
  font-size: 2.6rem;
}

.mobile .cloud a {
  padding: 5px 10px;
  border-radius: 5px;
  margin: 5px 2px;
  color: white;
  text-shadow: black 1px 1px 3px;
  float: left;
  display: block;
}

.mobile .cloud a.weight1 {
  background-color: var(--color-0);
}
.mobile .cloud a.weight2 {
  background-color: var(--color-1);
}
.mobile .cloud a.weight3 {
  background-color: var(--color-2);
}
.mobile .cloud a.weight4 {
  background-color: var(--color-3);
}
.mobile .cloud a.weight5 {
  background-color: var(--color-4);
}
.mobile .cloud a.weight6 {
  background-color: var(--color-5);
}
.mobile .cloud a.weight7 {
  background-color: var(--color-6);
}
.mobile .cloud a.weight8 {
  background-color: var(--color-7);
}
.mobile .cloud a.weight9 {
  background-color: var(--color-8);
}
.mobile .cloud a.weight10 {
  background-color: var(--color-9);
}
