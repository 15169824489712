.App {
  width: 100%;
  height: 100%;
}

.container {
  width: 100%;
  max-width: 960px;
  margin: auto;
  position: relative;
}


a.help {
  width: 100%;
  color: white;
  font-size: 1rem;
  background:rgb(185, 2, 2);
  padding: 5px 10px;
  position: relative;
  display: block;
  text-align: center;
  text-decoration: underline;
}

a.help:hover {
  background: rgb(224, 70, 70);
}
.contact .encrypted_mail:after {
  content: attr(data-name) "@" attr(data-domain) "." attr(data-tld); 
}



@media print {
  .page_wrapper_grid {
    display: block;
    width: 100%;
    margin-top: 0 !important;
  }
  .side_column {
    display: none;
  }
  header, footer {
    display: none;
  }
}