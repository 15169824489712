main {
  background: #f4f4f6;
  background-position: 50% 100%;
  padding: 40px 0;
  min-height: 300px;
  padding: 10px;
}
.mobile main {
  padding: 10px;
}

.page_wrapper_grid {
  display: grid;
  z-index: 1;
}
.page_wrapper_grid.with_side_column {
  grid-template: 1fr / 1fr 300px;
}
.page_wrapper_grid.without_side_column {
  grid-template: 1fr / 1fr;
}

.mobile .page_wrapper_grid {
  margin-top: 80px;
}

.desktop .page_wrapper_grid {
  margin-top: 150px;
}

.side_column {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 900px) {
  .page_wrapper_grid {
    display: flex;
    flex-direction: column;
  }

  .side_column {
    margin-left: 0;
    margin-top: 20px;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .sidebar_item {
    margin: 10px;
  }
}

