footer {
  height: 150px;
  color: #919986;
}
footer a {
  color: #bacb7b;
}

footer .top {
  height: 50%;
  background-repeat: repeat-x;
  background: url('../../assets/layout/bottom_bg_t.jpg');
}
footer .bottom {
  height: 50%;
  background: url('../../assets/layout/footer_shadow.png') 0 0 repeat-x;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

footer a.anchor {
  width: 28px;
  height: 30px;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  background: url('../../assets/layout/back_to_top.png') 0 0 no-repeat;
  color: transparent;
}
footer a.anchor:hover {
  background-position: 0 -40px;
}
