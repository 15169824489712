.sidebar_item {
  margin-bottom: 20px;
}
.sidebar_item h3 {
  height: 3.5rem;
  font-size: 2rem;
  background: url('../../assets/layout/header_h3.png') bottom center repeat-x;
  margin: 0px 0px 10px;
  padding: 0px;
  color: #28292a;
  text-shadow: 0 1px 0 rgb(255 255 255 / 70%);
  font-family: 'Waiting for the Sunrise';
}

.sidebar_item.wrapped {
  padding: 7px;
  background: rgb(231, 231, 231);
  border-radius: 7px;
  width: 300px;
}
.sidebar_item.wrapped .sidebar_item_internal_wrapper {
  padding: 0 12px 12px 12px;
  border: 1px solid #fff;
  background: #f8f8fa;
  overflow: hidden;
  border-radius: 3px;
}

.sidebar_item.unwrapped .list {
  display: flex;
  flex-direction: column;
}
.sidebar_item.unwrapped a {
  width: 100%;
  padding: 6px 6px;
}
.desktop .sidebar_item.unwrapped a {
  background: url('../../assets/layout/line_dotted_h.png') 0 1.8rem repeat-x;
}

.mobile .sidebar_item.unwrapped a {
  background: var(--color-link);
  color: white;
  margin: 4px;
  border-radius: 5px;
  text-align: center;
  font-size: 1.3rem;
}
