* {
  --color-main: #28292a;
  --color-light: #e2e2e7;
  --color-body: #646566;
  --color-link: #046dd6;
}

html,
body,
#root {
  width: 100vw;
  height: 100vh;
  background-image: url('./assets/layout/footer_bg.jpg');
  margin: 0;
  font-family: sans-serif;
  color: var(--color-body);
}
* {
  box-sizing: border-box;
  touch-action: manipulation;
}

button {
  border: none;
  background: none;
  cursor: pointer;
  background-repeat: no-repeat;
}

a {
  text-decoration: none;
  color: var(--color-link);
}

h1, h2, h3, h4 {
  font-weight: normal;
  color: var(--color-main);
  font-family: 'Waiting for the Sunrise';
}
h1 {
  margin: 0px 0px 12px 0px;
  font-size: 2.5rem;
}
h1 span {
  text-transform: capitalize;
}
h2 {
  margin: 0px 0px 8px 0px;
  font-size: 1.8rem;
}
h3 {
  margin: 0;
  font-size: 1.4rem;
  font-weight: bold;
}
h4 {
  margin: 0;
  font-size: 1.2rem;
  font-weight: bold;
}
p {
  margin: 10px 0 15px;
}

.loading {
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
